<template>
<div class="login-box building">

<div class="login-main">
    <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form login-form-box"
        @submit="handleSubmit"
    >
        <a-form-item class="login-input-item">
            <a-input class="mobile"
                v-decorator="[
          'userName',
          { rules: [{ required: true, message: '请输入账号!' }] },
        ]"
                placeholder="请输入账号"
            >
                <a-icon slot="prefix" type="user" autocomplete="off" style="color: rgba(0,0,0,.25)" />
            </a-input>
        </a-form-item>
        <a-form-item class="login-input-item">
            <a-input class="mobile" autocomplete="off"
                v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入正确的密码!' }] },
        ]"
                type="password"
                placeholder="请输入密码"
            >
                <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
            </a-input>

        </a-form-item>

        <a-form-item class="verify-input-item">
            <a-input class="VerificationCode" autocomplete="off" placeholder="请输入验证码" v-decorator="[
          'verification_code',
          { rules: [{ required: true, message: '请输入验证码' }] },
        ]"></a-input>
            <img :src="VerificationCodeSrc" @click="refreshCheckCode" style="width: 100%;
    position: absolute;
    left: 182px;"/>
        </a-form-item>

        <a-form-item>
            <a-checkbox
                v-decorator="[
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          },
        ]"
            >
               记住登录状态
            </a-checkbox>
            <a class="login-form-forgot" style="display: none;" href="">
                忘记密码
            </a>
            <div class="mt32 login-btn">
                <a-button type="primary" html-type="submit" class="login-form-button">
                    登录
                </a-button>
            </div>
        </a-form-item>
    </a-form>
</div>
</div>
</template>

<script>
import {generateUUID} from "ant-design-vue/lib/vc-select/util";
import JSEncrypt from 'jsencrypt'
export default {
    data(){

        return {
            type:'',
            VerificationCode:'',
            VerificationCodeSrc:'',
            signId:'',
            encipherData:{
                password:'',
                userName:'',
                verification_code:''
            },
            submitData:{
                EncipherData:'',
                clientSign:'',
                client_env:'',
                remember:false
            }
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'normal_login' });
    },
    created(){
        this.init();
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    let client_env = localStorage.getItem('client-env-platform')
                    //提交数据
                    this.submitData.client_env = client_env;
                    this.submitData.clientSign = this.signId;
                    //加密数据
                    this.encipherData.password = values.password;
                    this.encipherData.userName = values.userName;
                    this.encipherData.verification_code = values.verification_code;

                    this.submitData.EncipherData = this.rasEncryption(JSON.stringify(this.encipherData));

                    this.$http.api('admin/login',this.submitData).then(res=>{
                        if(res.verification_code === 0){
                            this.refreshCheckCode();
                            this.$message.error('验证码错误');
                        }else{
                            this.$message.success('登录成功');
                            localStorage.setItem('housekeeping-token',res.auth_token);
                            localStorage.setItem('client-site-info',res.site_info_name);
                            localStorage.setItem('userinfo',JSON.stringify(res.userinfo));
                            if(client_env === 'admin'){
                                this.$router.push('/indexAdmin');
                            }else{
                                this.$router.push('/index');
                            }
                        }


                    }).catch(res =>{
                        console.log('================='+res);
                    });

                    console.log('Received values of form: ', values);
                }
            });
        },
        init() {
            if(!localStorage.getItem('client-env-sign')){
                this.signId = generateUUID();
                localStorage.setItem('client-env-sign',this.signId)
            }else{
                this.signId = localStorage.getItem('client-env-sign');
            }
             this.VerificationCodeSrc = process.env.VUE_APP_BASE_API + "housekeeping/verify?clientSign="+this.signId;
             let hostName = window.location.hostname;
             this.type = this.$route.query.type;
             if(hostName != 'localhost'){
                 if(hostName.indexOf("admin") != -1){
                     localStorage.setItem('client-env-platform','admin');
                     this.setTitle('平台端');
                 }else{
                     localStorage.setItem('client-env-platform','shop');
                     this.setTitle('站点端');
                 }
             }else{
                 if(this.type != undefined){
                     localStorage.setItem('client-env-platform',this.type);
                     if(this.type === 'admin'){
                         this.setTitle('平台端');
                     }else{
                         this.setTitle('站点端');
                     }
                 }else{
                     this.setTitle('站点端');
                     localStorage.setItem('client-env-platform','shop');
                 }
             }


        },
        refreshCheckCode() {
            this.VerificationCodeSrc = process.env.VUE_APP_BASE_API + "housekeeping/verify?t="+new Date().getSeconds()+'&clientSign='+this.signId;
        },
        setTitle(str){
            let title = this.$route.meta.title;
            document.title = str +' -- '+ title;
        },
        rasEncryption(content){
            let encryptor = new JSEncrypt()
            var pubKey = "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHJc0bKFvikXooNaiwiwJYCgx64HDIabW5J7RXIT+OMw//kdfe+/+H2ZkDlv83litXa179mrz8IDwBpzTFS7z8KfzoWisf9jGlxS21Z1vEQ9CoFmLGoGBcgYNguJhJv8iCcA6luGBMqd4mm4L6XJx1FiSeDqpf0MaBdhBMNL8cElAgMBAAE=";
            encryptor.setPublicKey(pubKey)
            return encryptor.encrypt(content)
        }
    },
};
</script>
<style>

.building{
  /*background:url("https://img.gourdcare.com/ae96213595a3905b8a6f092e38bac05c1688632346.jpg");*/
  background:url("https://img.gourdcare.com/61fb36b2643e7bc2b18451611121165a1698993111.jpg");
  background-position: center center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.login-box, .login-main {
    width: 100vw;
    height: 100vh
}
#header-title{
    font-size: 20px;
    text-align: center;
}

.login-main {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2
}
.login-box  .text-center {

    position: relative;
    top: 15%;
    width: 100vw
}

.login-form-box {
    width: 420px;
    height: 450px;
    background: #fff;
    box-shadow: 0 4px 32px -8px rgba(0, 0, 0, .02);
    border-radius: 24px;
    margin-top: 36px;
    padding: 48px 40px
}

.login-input-item {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: 1px solid #dcdfe6;
    padding: 0 24px;
    margin-bottom: 30px;
}

.verify-input-item{
    width: 60%;
    height: 50px;
    border-radius: 30px;
    border: 1px solid #dcdfe6;
    padding: 0 24px;
    margin-bottom: 30px;
}
.verify-input-item .ant-input {
    border: none;
    font-size: 16px;
    margin-top: 8px;
    border-color: #FFFFFF !important;
}

.verify-input-item .ant-input:focus {
    border: none;
    box-shadow: none
}

.login-input-item .ant-input {
    border: none;
    font-size: 16px
}
.login-box .has-error .ant-form-explain{
    margin-top: 10px;
}
.login-input-item .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 66px
}

.login-input-item .mobile {
    width: 100%;
    margin-top: 8px !important;
    border-color: #FFFFFF !important;
}

.login-input-item .code {
    width: 200px
}

.login-input-item .ant-input-affix-wrapper .ant-input-prefix {
    color: #a6aec2;
    font-size: 16px
}

.login-input-item .ant-input:focus {
    border: none;
    box-shadow: none
}

.login-main .login-btn .ant-btn {
    height: 50px;
    border-radius: 25px;
    font-size: 16px
}
.login-main .ant-input-affix-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: start;
}
.login-main .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, .65);
    line-height: 0;
    transform: translateY(-50%);
}
.login-main .ant-btn-primary {
    color: #fff;
    background-color: #4772ff;
    border-color: #4772ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    width: 100%;
}
</style>
